import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"

import BackgroundSlider from "gatsby-image-background-slider"

export default () => (
  <Layout title="Página Inicial" key="Out">
    <div className="row">
      <div className="col s12 top-message valign-wrapper">
        <h1 className="valign">Tudo que você precisa na medida certa!</h1>
      </div>

      <div className="col s12 hommerfitness-slider">
        <div className="slider">
          <BackgroundSlider
            query={useStaticQuery(graphql`
              query {
                backgrounds: allFile(
                  sort: { fields: name, order: ASC }
                  filter: { sourceInstanceName: { eq: "backgrounds" } }
                ) {
                  nodes {
                    relativePath
                    childImageSharp {
                      fluid(maxWidth: 4000, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            `)}
          />
        </div>
      </div>

      <div className="col s12 prices-information">
        <div className="col s12 m6 left red-container valign-wrapper">
          <h5 className="valing">
            R$ 109,00
            <br />
            <span style={{ fontSize: "12px" }}>
              * Consulte horários disponíveis para essa promoção
            </span>
          </h5>
        </div>

        <div className="col s12 m6 right black-container">
          <div className="small-message right-align">
            <p>Média mensal</p>
            <p>
              <small>Somente cartão de crédito</small>
            </p>
          </div>

          <div className="normal-message">
            <h5 className="right-align">1 Ano - 12x de R$ 109,00</h5>
          </div>
        </div>
      </div>
    </div>

    <div className="line-divider"></div>

    <div className="global-inner">
      <div className="row">
        <div className="col s12 benefits-container">
          <div className="col s12 title">
            <h1>+Benefícios</h1>
            <h1 className="with-space">+Vantagens</h1>
          </div>

          <div className="col s12 benefits-wrapper">
            <div className="col s12 m6 topics">
              <ul>
                <li>No mercado há mais de 14 anos</li>
                <li>Aberta aos Domingos e Feriados</li>
                <li>Estacionamento no Local</li>
                <li>Total de 15 aparelhos ergométricos</li>
                <li>2 Professores por Sala</li>
              </ul>
            </div>

            <div className="col s12 m6 hours">
              <p className="center">Horário de Funcionamento:</p>
              <p>Segunda a Quinta - 05:30h às 23:00h</p>
              <p>Sexta - 05:30h às 22:00h</p>
              <p>Sábado - 9:00h às 15:00h</p>
              <p>Domingos e Feriados - 9:00h às 12:00h</p>
            </div>
          </div>
        </div>
      </div>

      <div className="line-divider"></div>

      <div className="row">
        <div className="col s12 modalities-container">
          <h1>Modalidade</h1>
          <div className="modalities-text center">
            <span>Musculação</span>
          </div>

          <p>
            Confira os horários clicando <Link to="/modalidades">aqui</Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)
